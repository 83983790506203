<template>
    <content-wrapper
        fluid
    >
        <schedule-member/>
    </content-wrapper>
</template>

<script>
import ContentWrapper from '@/components/ContentWrapper'
import ScheduleMember from '@apps/school/components/Schedule/ScheduleMember'

export default {
    name: 'Schedule',
    components: {
        ScheduleMember,
        ContentWrapper,
    }
}
</script>
